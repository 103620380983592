import './home-0310.css'
import '@splidejs/splide/css'
import Splide from '@splidejs/splide'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/all'

import { wfSliderArrows, wfTabs, addSplideClasses, sel, selAll, addStaticSplideClasses, selector, mm, splideCarousel, stLineWrap, pauseVideos } from './utils'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import SplitType from 'split-type'

gsap.registerPlugin(ScrollTrigger)

export default function Home() {
  // titles
  const title$a = selAll('.title--0310')
  ;[...title$a].forEach((header) => {
    const s$ = header.querySelector('.s')
    const h$ = header.querySelector('h2')
    const hSt = new SplitType(h$, { types: 'lines', linesClass: 'st-line' })
    stLineWrap(hSt.lines)
    const tl = gsap
      .timeline({ defaults: { ease: 'power4.out', duration: 1 } })
      .from(hSt.lines, { yPercent: 110, stagger: { amount: 0.1 } }, 0)
      .from(s$, { opacity: 0 }, 0.4)
    ScrollTrigger.create({
      trigger: header,
      start: 'top 80%',
      animation: tl,
    })
  })

  // hero sec
  pauseVideos()
  const navbar$ = sel('.navbar')
  const hero$ = sel('.product-header')
  const heroH$ = sel('.product-header__h')
  const heroP$ = sel('.header__p')
  const heroImgW$ = sel('.product-header__img-w.is--hero')
  const heroImg$ = heroImgW$.querySelector('video')
  const heroBtnW$ = hero$.querySelector('.btn-w')
  const heroBtn$a = heroBtnW$.querySelectorAll('.button')
  const propSec$ = sel('.home-props-sec')

  const heroHsT = new SplitType(heroH$, { types: 'words, chars' })
  const heroPsT = new SplitType(heroP$, { types: 'lines' })
  stLineWrap(heroPsT.lines)

  const heroTl = gsap.timeline({ defaults: { ease: 'power4.out', duration: 1 } })
  heroTl
    .from(
      heroHsT.chars,
      {
        opacity: 0,
        filter: 'blur(50px)',
        stagger: {
          amount: 0.5,
        },
        duration: 0.75,
      },
      0.5
    )
    .from(heroPsT.lines, { yPercent: 110, stagger: { amount: 0.2 } }, 1)
    // .from(heroBtn$a, { opacity: 0, stagger: { amount: 0.2 }, duration: 2.5 }, 1.25)
    .from(heroBtnW$, { opacity: 0, stagger: { amount: 0.5 }, duration: 5 }, 1.25)
    .from(heroImgW$, { x: 20, opacity: 0, duration: 2.5 }, 1.5)
    .from(navbar$, { opacity: 0, duration: 3 }, 2)
    .from(propSec$, { opacity: 0, duration: 2 }, '<=0.5')

  // props sec
  const propList$a = selAll('.props__tab')
  ScrollTrigger.create({
    trigger: propList$a[0],
    start: 'top 80%',
    animation: gsap.from(propList$a, {
      y: 50,
      opacity: 0,
      ease: 'power4.out',
      stagger: {
        amount: 0.2,
      },
      duration: 2,
    }),
  })

  // what sec
  const whatP$ = sel('.what__title__p')
  const whatCard$a = selAll('.what__item')

  const whatPsT = new SplitType(whatP$, { types: 'lines' })
  stLineWrap(whatPsT.lines)

  const whatTl = gsap.timeline({ defaults: { ease: 'power4.out', duration: 1 } })
  whatTl
    //
    .from(whatPsT.lines, { yPercent: 110, stagger: { amount: 0.2 } })
    .from(whatCard$a, { y: 20, opacity: 0, stagger: { amount: 0.2 }, duration: 2.5 }, 0.5)
  ScrollTrigger.create({
    trigger: whatP$,
    start: 'top 80%',
    animation: whatTl,
  })

  // how sec
  const howCard$a = selAll('.how__card')
  ScrollTrigger.create({
    trigger: howCard$a[0],
    start: 'top 80%',
    animation: gsap.from(howCard$a, {
      y: 50,
      opacity: 0,
      ease: 'power4.out',
      stagger: {
        amount: 0.5,
      },
      duration: 2,
    }),
  })

  // form sec
  const formLo$ = sel('.new-form__lo')
  const formLo$a = selAll('.new-form__lo>div')
  gsap.from(formLo$a, {
    scrollTrigger: {
      trigger: formLo$,
      start: 'top 75%',
    },
    x: 50,
    opacity: 0,
    stagger: {
      amount: 0.2,
    },
    ease: 'power4.out',
    duration: 3,
  })
}
