import '@splidejs/splide/css'
import Splide from '@splidejs/splide'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/all'

import { wfSliderArrows, wfTabs, addSplideClasses, sel, selAll, addStaticSplideClasses, selector, mm, splideCarousel } from './utils'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'

gsap.registerPlugin(ScrollTrigger)

export default function Home() {
  ;(() => {
    const _thumbSlider = '.header__thumb-slider'
    const _slider = '.header__slider'
    if (!sel(_thumbSlider) || !sel(_slider)) return
    addStaticSplideClasses(_thumbSlider)
    addStaticSplideClasses(_slider)
    const thumbSlider = new Splide(_thumbSlider, {
      arrows: false,
      pagination: false,
      gap: '3rem',
      perPage: 4,
      perMove: 1,
      speed: 1500,

      easing: 'cubic-bezier(0.16, 1, 0.3, 1)',
      // drag: 'free',
      // autoplay: false,
      // focus: 'center',
      // focus: 0,
      isNavigation: true,
      autoScroll: false,

      breakpoints: {
        991: {
          autoScroll: { speed: 1, autoStart: true },

          perPage: 3,
          gap: '2rem',
          type: 'loop',
          focus: 'center',

          // perMove: 2,
        },
        768: {
          perPage: 1,
          gap: '0.5rem',
          // perMove: 1,
        },
      },
    })
    const slider = new Splide(_slider, {
      type: 'fade',
      rewind: true, // to make it "loop" with the type fade
      arrows: false,
      pagination: false,
      gap: 0,
    })
    // thumbSlider.sync(slider)
    // .mount()
    thumbSlider.mount({ AutoScroll })
    // thumbSlider.mount()
    slider.mount()

    thumbSlider.on('active', (slide) => {
      slider.go(thumbSlider.index)
    })
    slider.on('dragged', () => {
      thumbSlider.go(slider.index)
    })
    // thumbSlider.Components.Autoplay.play()
    // thumbSlider.go
    splideCarousel(thumbSlider, 4000)
  })()

  sel('#btn-next').onclick = () => {
    sel('.form__right-arrow').click()
  }
  sel('#btn-prev').onclick = () => {
    sel('.form__left-arrow').click()
  }

  let formVars = { FirstName: '', LastName: '', Email: '' }
  const next$ = sel('#btn-next')

  for (const key in formVars) {
    const input = sel('#' + key)
    // console.log(input)

    input.addEventListener('input', function (e) {
      formVars[key] = e.target.value || ''

      const yep = Object.values(formVars).every((value) => value !== '') && formVars.Email.includes('@')
      if (yep) next$.classList.remove('is--off')
      else next$.classList.add('is--off')
    })
  }
}
