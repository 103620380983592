import './style.styl'
import { sel, selAll, wfTabs, mm } from './utils'
import Home from './home'
import Home0310 from './home-0310'
import Lp from './lp'
import Case from './case'
/* 
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Lenis from '@studio-freight/lenis'

const lenis = new Lenis()
lenis.on('scroll', ScrollTrigger.update)
gsap.ticker.add((time) => {
  lenis.raf(time * 1000)
})
gsap.ticker.lagSmoothing(0) */

const _dataPage = sel('.page-wrapper') || sel('body')
const dataPage = _dataPage?.getAttribute('data-page')
switch (dataPage) {
  case 'home':
    Home()
    break
  case 'home-0310':
    Home0310()
    break
  case 'lp':
    Lp()
    break

  case 'case':
    Case()
    break
  case 'error':
    Error()
    break
  default:
    console.log('unknown data-page:', dataPage)
}

// pre 03/13'25
function megamenu() {
  wfTabs('tabs-mm', 'mouseenter')
  const navbarTabs$a = selAll('.navbar__mm__tab')

  function clickTabHandle(tab, pane) {
    const button$ = sel(`#${pane} .button`)
    button$.click()
  }
  navbarTabs$a.forEach((tab) => {
    // tab.addEventListener('mouseenter', function () {
    //   changeTabHandle(this)
    // })
    tab.addEventListener('click', function (e) {
      // e.preventDefault()
      mm.add('(max-width: 991px)', () => {
        e.stopPropagation()
      })

      const pane = tab.dataset.paneId

      clickTabHandle(this, pane)
    })
  })
}
// new non megamenu dd
const navbarDdMod$a = selAll('.navbar__dd__mod')
if (navbarDdMod$a.length > 0) {
  navbarDdMod$a.forEach((ddMod) => {
    const navDdItem$a = ddMod.querySelectorAll('.navbar__dd__item')
    navDdItem$a.forEach((ddItem) => {
      const itemA$ = ddItem.querySelector('a')
      const handle = (e) => {
        // e.preventDefault()
        // itemA$.click()
        // get url from href and go to it
        const _href = itemA$.getAttribute('href')
        // add full url if not full url
        const href = _href.startsWith('http') ? _href : window.location.origin + _href
        const target = itemA$.getAttribute('target')
        // console.log('click', ddItem, itemA$, e)
        console.log(href)
        if (target === '_blank') {
          window.open(href, target)
        } else {
          window.location = href
        }
      }
      ddItem.onclick = handle
      // ddItem.addEventListener('click', handle)
      // console.log(item)
    })
  })
} else {
  megamenu()
}
