import '@splidejs/splide/css'
import Splide from '@splidejs/splide'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/all'

import { wfSliderArrows, wfTabs, addSplideClasses, sel, selAll, addStaticSplideClasses, selector, mm, splideCarousel, stLineWrap } from './utils'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import SplitType from 'split-type'

gsap.registerPlugin(ScrollTrigger)

export default function Lp() {
  console.log('lp')

  const navbar$ = sel('.navbar')
  const hero$ = sel('.lp-hero')
  const heroC$ = sel('.lp-hero__c')
  const heroInfo$ = sel('.lp-hero__info')
  const heroH$ = sel('.lp-hero__info__h')
  const heroP$ = sel('.lp-hero__info__p')
  const heroBtnW$ = sel('.lp-hero__btn-w')
  const heroBtn$a = selAll('.lp-hero__btn-w .button')
  const heroBg$ = sel('.lp-hero__bg-w')

  const heroIcoW$ = sel('.lp-hero__icons')
  const heroIcoDis$ = sel('.lp-hero__icons__img.is--dis')
  const heroIcoX$ = sel('.lp-hero__icons__img.is--x')
  const heroIcoTel$ = sel('.lp-hero__icons__img.is--tel')
  const heroIcoFb$ = sel('.lp-hero__icons__img.is--fb')
  const heroIcoIg$ = sel('.lp-hero__icons__img.is--ig')

  const heroHsT = new SplitType(heroH$, { types: 'words, chars' })
  const heroPsT = new SplitType(heroP$, { types: 'lines' })
  stLineWrap(heroPsT.lines)

  const heroTl = gsap.timeline({ defaults: { ease: 'power4.out', duration: 1 } })
  heroTl
    .from(
      heroHsT.chars,
      {
        opacity: 0,
        filter: 'blur(50px)',
        stagger: {
          amount: 0.5,
        },
        duration: 0.75,
      },
      0.5
    )
    .from(heroBg$, { scale: 1.1, duration: 6 }, 0)
    .from(heroPsT.lines, { yPercent: 110, stagger: { amount: 0.2 } }, 1)
    .from(heroBtnW$, { opacity: 0, stagger: { amount: 0.5 }, duration: 5 }, 1.25)
    .from([heroIcoDis$, heroIcoX$, heroIcoTel$, heroIcoFb$, heroIcoIg$], { y: 20, opacity: 0, stagger: { amount: 0.5 }, duration: 2.5 }, 1.5)
    .from(navbar$, { opacity: 0, duration: 3 }, 2)

  // gsap.to(heroBg$, {
  //   scrollTrigger: {
  //     trigger: hero$,
  //     start: 'top top',
  //     end: 'bottom top',
  //     scrub: 0,
  //   },
  //   // scale: 1.1,
  //   yPercent: 20,
  // })
  const benSec$ = sel('.benefits-sec')

  const heroStTl = gsap
    .timeline({ defaults: { ease: 'none', duration: 1 } })
    .to(heroInfo$, { scale: 0.9 }, 0)
    // .to([heroIcoX$, heroIcoDis$, heroIcoTel$], { scale: 1.2 }, 0)
    .to(hero$, { filter: 'blur(10px)', ease: 'power4.in' }, 0)
    .to(heroC$, { opacity: 0 }, 0)

  ScrollTrigger.create({
    trigger: benSec$,
    start: 'top bottom=+1',
    end: 'top top',
    pin: hero$,
    pinSpacing: false,
    scrub: 0,
    animation: heroStTl,
  })

  const propItem$a = selAll('.benefits__item')
  gsap.from(propItem$a, {
    scrollTrigger: {
      trigger: '.benefits__item-w',
      start: 'top 80%',
    },
    y: 50,
    opacity: 0,
    ease: 'power4.out',
    stagger: {
      amount: 0.4,
    },
    duration: 1.2,
  })

  const headerLp$a = selAll('.header--lp')
  const featuresHeader$ = sel('.features__header')
  ;[...headerLp$a, featuresHeader$].forEach((header) => {
    const s$ = header.querySelector('.s--lp')
    const h$ = header.querySelector('h2')
    const hSt = new SplitType(h$, { types: 'lines', linesClass: 'st-line' })
    stLineWrap(hSt.lines)
    const tl = gsap
      .timeline({ defaults: { ease: 'power4.out', duration: 1 } })
      .from(hSt.lines, { yPercent: 110, stagger: { amount: 0.1 } }, 0)
      .from(s$, { opacity: 0 }, 0.4)
    ScrollTrigger.create({
      trigger: header,
      start: 'top 50%',
      animation: tl,
    })
  })

  const getsWeb3$ = sel('.benefits__web3')
  const getsWeb3H$ = getsWeb3$.querySelector('h3')
  const getWeb3P$a = selAll('.benefits__web3__lo div')
  gsap.from([getsWeb3H$, ...getWeb3P$a], {
    scrollTrigger: {
      trigger: getsWeb3$,
      start: 'top 50%',
    },
    x: 50,
    opacity: 0,
    ease: 'power4.out',
    stagger: {
      amount: 0.4,
    },
    duration: 1.2,
  })

  const mod$ = sel('.case__mod')
  gsap.from(mod$, {
    scrollTrigger: {
      trigger: mod$,
      start: 'top 50%',
    },
    y: 50,
    opacity: 0,
    ease: 'power4.out',
    duration: 1.2,
  })

  const featureList$ = sel('.features__list')
  const featureItem$a = selAll('.features__item')
  gsap.from(featureItem$a, {
    scrollTrigger: {
      trigger: featureList$,
      start: 'top 50%',
    },
    y: 50,
    opacity: 0,
    ease: 'power4.out',
    stagger: {
      amount: 0.4,
    },
    duration: 1.2,
  })

  const featuresCta$ = sel('.features__cta')
  gsap.from(featuresCta$, {
    scrollTrigger: {
      trigger: featuresCta$,
      start: 'top 75%',
    },
    y: 50,
    opacity: 0,
    ease: 'power4.out',
    duration: 1.2,
  })

  const formLo$ = sel('.new-form__lo')
  const formLo$a = selAll('.new-form__lo>div')
  gsap.from(formLo$a, {
    scrollTrigger: {
      trigger: formLo$,
      start: 'top 75%',
    },
    x: 50,
    opacity: 0,
    stagger: {
      amount: 0.2,
    },
    ease: 'power4.out',
    duration: 1.2,
  })
}
