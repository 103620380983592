import '@splidejs/splide/css'
import Splide from '@splidejs/splide'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/all'

import { wfSliderArrows, wfTabs, addSplideClasses, sel, selAll, addStaticSplideClasses, selector, mm, splideCarousel, stLineWrap } from './utils'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import SplitType from 'split-type'

gsap.registerPlugin(ScrollTrigger)

export default function Case() {
  console.log('case')

  const navbar$ = sel('.navbar')
  const hero$ = sel('.case-hero')
  const heroC$ = sel('.lp-hero__c')
  const heroInfo$ = sel('.lp-hero__info')
  const heroH$ = sel('.lp-hero__info__h')
  const heroP$ = sel('.lp-hero__info__p')
  const heroStat$a = selAll('.case-hero__stat__item')
  const heroStatNum$a = selAll('.case-hero__stat__num')
  const heroBg$ = hero$.querySelector('.bg-w')
  const heroImgW$ = sel('.case-hero__img-w')
  const heroImg$ = heroImgW$.querySelector('.img-a')
  const heroLogo$ = sel('.case-hero__img__logo')

  const heroHsT = new SplitType(heroH$, { types: 'words, chars' })
  const heroPsT = new SplitType(heroP$, { types: 'lines' })
  stLineWrap(heroPsT.lines)

  const heroTl = gsap.timeline({ defaults: { ease: 'power4.out', duration: 1 } })
  heroTl
    .from(
      heroHsT.chars,
      {
        opacity: 0,
        filter: 'blur(50px)',
        stagger: {
          amount: 0.5,
        },
        duration: 0.75,
      },
      0.5
    )
    .from(heroBg$, { scale: 1.1, duration: 6 }, 0)
    .from(heroPsT.lines, { yPercent: 110, stagger: { amount: 0.2 } }, 1)
    .from(heroStat$a, { y: 20, opacity: 0, stagger: { amount: 0.2 }, duration: 2.5 }, 1.25)
    .from(heroStatNum$a, { innerText: 0, snap: { innerText: 1 }, duration: 5, stagger: { amount: 1.5 } }, 1.25)
    // .from(heroBtnW$, { opacity: 0, stagger: { amount: 0.5 }, duration: 5 }, 1.25)
    .from(heroImgW$, { x: 20, opacity: 0, duration: 2.5 }, 1.5)
    .from(navbar$, { opacity: 0, duration: 3 }, 2)

  const propItem$a = selAll('.benefits__item')
  gsap.from(propItem$a, {
    scrollTrigger: {
      trigger: '.benefits__item-w',
      start: 'top 80%',
    },
    y: 50,
    opacity: 0,
    ease: 'power4.out',
    stagger: {
      amount: 0.4,
    },
    duration: 1.2,
  })

  const featuresTitle$ = sel('.features__title')
  const featuresTitleSt = new SplitType(featuresTitle$, { types: 'lines, words', lineClass: 'st-line-inline' })
  // stLineWrap(featuresTitleSt.lines)
  const titleSt = gsap.timeline({ defaults: { ease: 'power4.out', duration: 2 }, paused: true })
  titleSt
    // .from(featuresTitleSt.lines, { yPercent: 110, stagger: { amount: 0.2 } }, 0)

    // .from(featuresTitle$, { borderColor: 'rgba(255,255,255,0)' }, 1)
    .from(featuresTitleSt.words, { opacity: 0, stagger: { amount: 0.6 } }, 0)

    // .from(featuresTitle$, { opacity: 0, y: 50 }, 0)
    .from(featuresTitle$, { borderBottomColor: 'transparent' }, 1)
  ScrollTrigger.create({
    trigger: featuresTitle$,
    start: 'top 50%',
    animation: titleSt,
  })
  const featuresHeader$ = selAll('.features__header')
  ;[...featuresHeader$].forEach((header) => {
    // console.log(header)

    const s$ = header.querySelector('.s--lp')
    const h$ = header.querySelector('h2')
    const hSt = new SplitType(h$, { types: 'lines', linesClass: 'st-line' })
    stLineWrap(hSt.lines)
    const tl = gsap
      .timeline({ defaults: { ease: 'power4.out', duration: 1 } })
      .from(hSt.lines, { yPercent: 110, stagger: { amount: 0.1 } }, 0)
      .from(s$, { opacity: 0 }, 0.4)
    ScrollTrigger.create({
      trigger: header,
      start: 'top 50%',
      animation: tl,
    })
  })

  const featureList$ = sel('.features__list')
  const featureItem$a = selAll('.features__item')
  gsap.from(featureItem$a, {
    scrollTrigger: {
      trigger: featureList$,
      start: 'top 50%',
    },
    y: 50,
    opacity: 0,
    ease: 'power4.out',
    stagger: {
      amount: 0.4,
    },
    duration: 1.2,
  })

  const cta$ = sel('.case__cta')
  const ctaP$ = sel('.features__cta__p')
  const ctaBtn$ = cta$.querySelector('.button-group')

  const ctaTl = gsap
    .timeline({ defaults: { ease: 'power4.out', duration: 2 }, paused: true })
    .from(ctaP$, { y: 50, opacity: 0 }, 0)
    .from(ctaBtn$, { y: 50, opacity: 0 }, 0.2)
  ScrollTrigger.create({
    trigger: cta$,
    start: 'top 50%',
    animation: ctaTl,
  })

  const forward$ = sel('.forward-sec')
  const forwardH$ = forward$.querySelector('h2')
  const forwardP$A = selAll('.forward__p-w>div')

  const forwardHSt = new SplitType(forwardH$, { types: 'lines', linesClass: 'st-line' })
  stLineWrap(forwardHSt.lines)
  const forwardTl = gsap
    .timeline({ defaults: { ease: 'power4.out', duration: 2 } })
    .from(forwardHSt.lines, { yPercent: 110, stagger: { amount: 0.1 } }, 0)
    .from(forwardP$A, { y: 50, opacity: 0, stagger: { amount: 0.15 } }, 0.25)
  ScrollTrigger.create({
    trigger: forward$,
    start: 'top 50%',
    animation: forwardTl,
  })

  const formLo$ = sel('.new-form__lo')
  const formLo$a = selAll('.new-form__lo>div')
  gsap.from(formLo$a, {
    scrollTrigger: {
      trigger: formLo$,
      start: 'top 75%',
    },
    x: 50,
    opacity: 0,
    stagger: {
      amount: 0.2,
    },
    ease: 'power4.out',
    duration: 1.2,
  })
}
